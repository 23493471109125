<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>Forgot Password</h2>
          </div>
          <section class="study_myinfo_list_area">
            <div style="width:90%;margin:auto;">
              <section class="study_main_box">
                <div style="width:100%;">
                  <div class="signup_left_box"></div>
                  <div class="signup_right_box">
                    <div id="login_content">
                      <form
                        class="login_form"
                        @submit.prevent="validationForm"
                      >
                        <div class="login_form_box">
                          <input type="email" v-model="formData.email" class="login_input_box_01_round moon_email" placeholder="e-mail 주소" alt="이메일 주소" style="margin-bottom:10px">
                        </div>
                      </form>
                      <div style="width:230px;margin:auto;text-align:left;">
                        <button type="button" class="btn loginBtn_sheet" @click="validationForm">
                          <a href="#">확인</a>
                        </button>
                        <button type="button" class="btn passwordBtn_sheet" @click.prevent="$router.push({ name: 'login' })" style="width:170px;margin-left:-10px;padding-left:35px;">
                          <a href="#" >취소</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'notification' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'
import User from '@/models/User'

export default {
  name: 'ForgotPassword',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  data () {
    return {
      formData: {
        email: '',
        host: `${window.location.protocol}//${window.location.host}`
      }
    }
  },
  methods: {
    async validationForm () {
      try {
        const response = await User.forgotPassword(this.formData)
        if (response.data.success) {
          this.formData.email = ''
          this.$swal({
            title: response.data.title,
            text: response.data.message,
            icon: 'success'
          })
        } else {
          this.$swal({
            title: 'Error',
            text: response.data.message,
            icon: 'error'
          })
        }
      } catch (e) {
        this.$swal({
          title: 'Error',
          text: e.response.data,
          icon: 'error'
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
